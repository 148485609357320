@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #eff2f5;
}
